import { withPrefix } from "gatsby"


const LANG_LIST  = [
    {
        value: 'en',
        label: 'English',
        flag: withPrefix('/flags/en.png')
    },
    {
        value: 'es',
        label: 'Spanish',
        flag: withPrefix('/flags/es.png')
    },
    {
        value: 'pt',
        label: 'Portugese',
        flag: withPrefix('/flags/pt.png')
    },
    {
        value: 'ar',
        label: 'Arabic',
        flag: withPrefix('/flags/ar.png')
    },
    {
        value: 'ko',
        label: 'Korean',
        flag: withPrefix('/flags/ko.png')
    },
    {
        value: 'ja',
        label: 'Japanese',
        flag: withPrefix('/flags/jp.png')
    },
    {
        value: 'zh',
        label: 'Chinese',
        flag: withPrefix('/flags/zh.png')
    },
    {
        value: 'ms',
        label: 'Malay',
        flag: withPrefix('/flags/ms.png')
    },
    {
        value: 'de',
        label: 'German',
        flag: withPrefix('/flags/de.png')
    },
    {
        value: 'fr',
        label: 'French',
        flag: withPrefix('/flags/fr.png')
    },
    {
        value: 'ru',
        label: 'Russian',
        flag: withPrefix('/flags/ru.png')
    }
]

export default LANG_LIST

