import React, { useState } from "react"
import  cn from "classnames"
import { t } from "@lingui/macro"
import Button from "../../components/Button"
import { navigate, withPrefix } from "gatsby"
import NavFlags from "../../components/NavFlags"

function MarketingNavigation(props) {
  const {
    className,
    langCode,
    noNav
  } = props
  const [ mobNavOpen, setMobNavOpen ] = useState(false)
  const imgLogoSrc = withPrefix('logo.png')
  const langUrl = (langCode !== 'en' && langCode !== 'es') ? `${langCode}/` : ''
  const slugLangUrl = (langCode !== 'en' && langCode !== 'es') ? `-${langCode}` : ''

  const MENU_LIST = [
    {
      id:'home',
      label: t`Home`,
      url: 'http://cpucoin.io/',
      target: '_blank'
    },
    {
      id: 'overview',
      label: t`Overview`,
      url: 'https://cpucoin.io/#overview',
      target: '_blank',
      submenu: [
        {
          id:'problem',
          label: t`PROBLEM`,
          url: `https://cpucoin.io/${langUrl}#problem`,
          target: '_blank'
        },
        {
          id:'solution',
          label: t`SOLUTION`,
          url: `https://cpucoin.io/${langUrl}#solution`,
          target: '_blank'
        },
        {
          id:'features',
          label: t`FEATURES`,
          url: `https://cpucoin.io/${langUrl}#features`,
          target: '_blank'
        }
      ]
    },
    {
      id: 'news',
      label: t`News`,
      url: 'https://cpucoin.io/news',
      target: '_blank'
    },
    {
      id: 'roadmap',
      label: t`Roadmap`,
      url: `https://cpucoin.io/${langUrl}#roadmap`,
      target: '_blank'
    },
    {
      id: 'tokenomics',
      label: t`Tokenomics`,
      url: `https://cpucoin.io/${langUrl}#tokenomics`,
      target: '_blank'
    },
    {
      id: 'team',
      label: t`Team`,
      url: `https://cpucoin.io/${langUrl}#team`,
      target: '_blank'
    },
    {
      id: 'testnet',
      label: t`CPUcoin TestNet`,
      url: '',
      target: '_blank',
      submenu: [
        {
          id: 'testnet-dashboard',
          label: t`TestNet Dashboard`,
          url: 'https://dappublisher.mediarich.io/mrm/cgn/cgndash/',
          target: '_blank'
        },
        {
          id: 'downloads',
          label: t`Downloads`,
          url: `https://cpucoin.io/${langUrl}mineroffers${slugLangUrl}/`,
          target: '_blank'
        },
        {
          id: 'publisher-dapp',
          label: t`Publisher dApp`,
          url: 'https://dappublisher.mediarich.io/ui/?box=TestCustomer',
          target: '_blank'
        },
        {
          id: 'dservice',
          label: t`dService`,
          url: 'https://equilibrium.com/mediarichserver/',
          target: '_blank'
        },
        {
          id: 'support',
          label: t`Support`,
          url: 'https://cpucoin.io/cgn/support/',
          target: '_blank'
        },
        {
          id: 'exchange-wallet-partners',
          label: t`Exchange Wallet Partners`,
          url: 'https://cpucoin.io/partners/wallets/',
          target: '_blank'
        },
        {
          id: 'boinc',
          label: t`Boinc`,
          url: 'https://cpucoin.io/boinc/',
          target: '_blank'
        }
      ]
    },
    {
      id: 'about',
      label: t`About`,
      url: 'https://cpucoin.io/about-2/',
      submenu: [
        {
          id: 'legal',
          label: t`Legal`,
          url: 'https://cpucoin.io/legal/',
          target: '_blank'
        },
        {
          id: 'privacy-policy',
          label: t`Privacy Policy`,
          url: 'https://cpucoin.io/privacy/',
          target: '_blank'
        },
        {
          id: 'faq',
          label: 'faq',
          url: `https://cpucoin.io/${langUrl}#faq`,
          target: '_blank'
        },
        {
          id: 'contact',
          label: t`Contact`,
          url: `https://cpucoin.io/${langUrl}#contact`,
          target: '_blank'
        }
      ]
    },
    {
      id: 'mineroffers',
      label: t`Miner Software Subscription`,
      url: `https://cpucoin.io/${langUrl}mineroffers${slugLangUrl}/`,
      target: '_blank'
    }
  ]

  const renderItem = (menuItem) => {
    return (
      <MenuItem
        url={menuItem.url}
        target={menuItem.target}
        label={menuItem.label}
        submenu={menuItem.submenu}
        isSubMenuOpen={false}
      />
    )
  }

  const onToggleNav = () => {
    setMobNavOpen(!mobNavOpen)
  }

  return (
    <div className={cn(`nav ${className}`, {
      "nav--mobile-open": mobNavOpen,
      "nav--mobile-closed": !mobNavOpen
    })}>
      <div className="container">
        <div className="nav__logo">
          <a href="https://cpucoin.io" target="_blank">
            <img src={imgLogoSrc} alt=""/>
          </a>
        </div>


        <div className="nav__mobile-container">
          { !noNav && (
            <ul className="nav__menu">
              { MENU_LIST.map(m => renderItem(m))}
            </ul>
          )}
          <div className="nav__actions">
            <Button
              bttnType="primary"
              label={t`CPUcoin Login`}
              onClick={() => { navigate(`/${langCode}/login`) }}
            />
            <NavFlags />
          </div>
        </div>

        <Button
          icon
          iconEl={<i className="wtfs wtf-bars"/>}
          bttnType="primary"
          className="nav__mobile-toggle"
          onClick={onToggleNav}
        />

      </div>
    </div>
  )
}

MarketingNavigation.defaultProps = {
  className: ''
}

function MenuItem(props) {
  const {
    url ,
    target ,
    label ,
    submenu ,
    isSubMenuOpen
  } = props

  const [ subMenuOpen , setSubMenuOpen ] = useState(isSubMenuOpen)


  const onMenuItemClick = () => {
    const hasSubMenu = submenu && submenu.length > 0
    if (hasSubMenu) {
      setSubMenuOpen(!subMenuOpen)
    }
  }

  return (
    <li
      className={cn(`nav__menu__item`, {
        "nav__menu__item--submenu-open": subMenuOpen,
        "nav__menu__item--submenu-closed": !subMenuOpen
      })}
      onClick={onMenuItemClick}
    >
      <a
        href={ url }
        className="nav__menu__item__link"
        target={target}
        rel="noreferrer"
      >
        {label}
      </a>
      {submenu && (
        <>
          <i className="wtfs wtf-chevron-down nav__menu__item__submenu-toggler"/>
          <ul className="nav__menu__item__submenu">
            {submenu.map(i => <MenuItem { ...i }/>)}
          </ul>
        </>
      )}
    </li>
  )
}

export default MarketingNavigation
