import React, { useMemo } from "react"
import Login from "../components/Auth/Login"
import SEO from "../components/seo"
import { t } from "@lingui/macro"
import { deleteCookie } from "../utils/tools"
import { navigate } from "gatsby"

const IndexPageInner = (props) => {
  const {
    langCode,
    isFromLogout,
    redirectLang
  } = props

  const isFromLogoutProp = useMemo(() => {
    return isFromLogout
  }, [isFromLogout])

  const redirectLangProp = useMemo(() => {
    return redirectLang
}, [redirectLang])



  if (isFromLogoutProp && redirectLangProp) {
    let navigateTo = `https://cpucoin.io/${redirectLangProp}/cpucoin-home-${redirectLangProp}/`
    if (redirectLangProp === 'en') {
      navigateTo = `https://cpucoin.io`
    }

    if (redirectLangProp === 'es' || redirectLangProp === 'pt' ) {
      navigateTo = `https://cpucoin.io/pt/cpucoin-new-pr/`
    }

    deleteCookie('isFromLogout')
    navigate(navigateTo)
    return (<></>)
  }

  console.log('[*][login] IndexPageInner langCode == ', langCode)
  console.log('[*][login] IndexPageInner isFromLogoutProp == ', isFromLogoutProp)

  return (
    <>
      <SEO
        title={t`Login`}
        description={t`Building the Sharing Economy to Enable The Global Computing Network`}
        lang={langCode}
      />
      <Login langCode={langCode}/>
    </>
  )
}

export default IndexPageInner
