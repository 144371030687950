import React, { useContext } from "react"
import Select from 'react-select'
import LANG_LIST from "../mockData/langlist"
import { LangContext } from "../contexts/langcontext"
import { eqLogger } from "../utils/tools"

const NavFlags = () => {
  const [globalLang, langMethods] = useContext(LangContext)
  const defaultValue = LANG_LIST.findIndex(i => i.value === globalLang)

  eqLogger('NavFlags globalLang === ', globalLang)
  eqLogger('NavFlags defaultValue === ', defaultValue)

  const selectFlagsFormat = (dat) => {
    return (
      <div className="flag-item">
        <div className="flag-item__logo">
          <img src={dat.flag} alt=""/>
        </div>
        <div className="flag-item__label">
          {dat.label}
        </div>
      </div>
    )
  }

  const onChangeLanguage = (val) => {
    langMethods.changeLang(val.value)


  }


  return (
    <Select
        className="nav-flags"
        classNamePrefix="nav-flags"
        isSearchable
        name="wallet_provider"
        options={LANG_LIST}
        defaultValue={LANG_LIST[defaultValue]}
        onChange={onChangeLanguage}
        formatOptionLabel={selectFlagsFormat}
    />
  )
}

NavFlags.defaultProps = {
  openOnDefault: false,
  list: [],
  icon: false,
  className: ''
}

export default NavFlags
