import React from "react"

import { i18n } from '@lingui/core'
import { messages } from '../../../locales/en/messages'
import Layout from "../../../components/layout"

const AccountSettings = () => {
  const langCode = "en"
  i18n.load(langCode, messages)

  return (
    <Layout
      langCode={langCode}
      messages={messages}
    >
      <h1>test</h1>
    </Layout>
  )
}

export default AccountSettings