import React from "react"

import Layout from "../components/layout"


import { i18n } from '@lingui/core'
import { messages } from '../locales/ru/messages'
import IndexPageInner from "../innerPages/IndexPageInner"
import { getCookie, eqLogger } from "../utils/tools"

const IndexPage = () => {
  const langCode = "ru"
  const isFromLogout = getCookie('isFromLogout')
  const redirectLang = getCookie('isFromLanguage') || langCode
  eqLogger('[*][login] IndexPage isFromLogout == ', isFromLogout)

  i18n.load(langCode, messages)

  return (
    <Layout
      langCode={langCode}
      messages={messages}
    >
      <IndexPageInner
        langCode={langCode}
        isFromLogout={isFromLogout}
        redirectLang={redirectLang}
      />
    </Layout>
  )
}

export default IndexPage
