import React from "react"


import Layout from "../components/layout"

import { i18n } from '@lingui/core'
import { messages } from '../locales/es/messages'
import DashboardPageInner from "../innerPages/DashboardPageInner"

const DashboardPage = () => {
  const langCode = "es"
  i18n.load(langCode, messages)

  return (
    <Layout
      langCode={langCode}
      messages={messages}
    >
      <DashboardPageInner langCode={langCode} />
    </Layout>
  )
}


export default DashboardPage
