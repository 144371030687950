import React from "react"
import { t, Trans } from "@lingui/macro"
import Button from "../components/Button"
import MarketingNavigation from "./decorative/MarketingNavigation"
import SEO from "../components/seo"

const NotFoundPageInner = (props) => {
  const {
    title = t`We can't seem to find the page your looking for`,
    moreInfo = t`The page you are looking for was either moved, removed, renamed or might never existed!`,
    langCode
  } = props

  return (
    <>
      <SEO
        title={title}
        description={moreInfo}
        lang={langCode}
      />
      <div className="notfound-page">
        <MarketingNavigation
          langCode={langCode}
          noNav
        />
        <section className="pageNotFound">
          <div className="container">
            <div className="row">
              <div className="col col-md-5 col-direction-column">
                <h1 className="pageNotFound__code">
                  404
                </h1>
                <h3 className="pageNotFound__title">
                  { title }
                </h3>
                <p className="pageNotFound__desc">
                  { moreInfo }
                </p>
                <Button className="pageNotFound__go-back" primary>
                  <Trans>Go Back</Trans>
                </Button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}


export default NotFoundPageInner