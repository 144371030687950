import React, { useContext, useMemo } from "react"
import { Link, withPrefix, navigate } from "gatsby"
import { AuthContext } from "../../contexts/authcontext"

import { Trans, t } from '@lingui/macro';
import NavFlags from "../NavFlags";
import { eqLogger, deleteCookie, getCookie } from "../../utils/tools";


function Login(props) {
  const { langCode } = props
  const [authObj, authMethods]= useContext(AuthContext)

  deleteCookie('isFromSessionExpiry')

  const isFromLogout = getCookie('isFromLogout')
  eqLogger('[*][login] isFromLogout == ', isFromLogout)
  eqLogger('[*][login] authObj == ', authObj)

  const imgLogoSrc = withPrefix('logo.png')

  const handleLogin = () => {
    authMethods.login()
  }
  const handleSignup = () => {
    authMethods.signUp()
  }

  const NoAuthState = () => {
    return (
      <>
        <div className="login-page__card__logo">
          <img src={imgLogoSrc} alt=""/>
        </div>
        <h1 className="login-page__card__title">
          <Trans>Welcome to the</Trans>
          <br/>
          <Trans>CGN Miner Dashboard</Trans>
        </h1>
        <button className="login-page__card__login" onClick={handleLogin}>
          <Trans>Login</Trans>
        </button>
        <small className="login-page__card__small">
          <Trans>Don’t have an account?</Trans>
          <span onClick={handleSignup}>
            <Trans>Signup</Trans>
          </span>
        </small>
      </>
    )
  }

  const hasSession = useMemo(() => {
    if (Object.keys(authObj).length > 0 && authObj.access_token) {
      return true
    }
  }, [authObj])

  eqLogger('LOGIN hasSession == ', hasSession)

  if (hasSession) {
    navigate(`/${langCode}/dashboard`)
    return false
  }

  return (
    <div className="login-page">
      <div className="login-page__change-lang">
        <p className="login-page__change-lang__label">
          <Trans>Language: </Trans>
        </p>
        <NavFlags />
      </div>
      <img
        src={withPrefix('login_bg.png')}
        alt={t`CPUcoin Miner Dashboard Login`}
        className="login-page__bg"
      />
      <div className="login-page__card">
        { !hasSession && (
          <NoAuthState />
        )}
      </div>
    </div>
  )
}


export default Login
