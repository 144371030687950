import React from "react"

import Layout from "../components/layout"

import { i18n } from '@lingui/core'
import { messages } from '../locales/fr/messages'
import IndexPageInner from "../innerPages/IndexPageInner"

const IndexPage = () => {
  const langCode = "fr"
  i18n.load(langCode, messages)

  return (
    <Layout
      langCode={langCode}
      messages={messages}
    >
      <IndexPageInner langCode={langCode}/>
    </Layout>
  )
}

export default IndexPage
