import React from "react"

import { i18n } from '@lingui/core'
import { messages } from '../../locales/ko/messages'
import Layout from "../../components/layout"
import NotFoundPageInner from "../../pageComponents/404pageinner"

const NotFoundPage = () => {
  const langCode = "ko"
  i18n.load(langCode, messages)

  return (
    <Layout
      langCode={langCode}
      messages={messages}
    >
      <NotFoundPageInner langCode={langCode} />
    </Layout>
  )
}

export default NotFoundPage