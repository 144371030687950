import React, { useContext, useEffect } from "react"
import { AuthContext } from "../contexts/authcontext"
import { eqLogger } from "../utils/tools"
import { Trans, t } from "@lingui/macro"
import SEO from "../components/seo"

function CallbackPageInner(props) {
  const {
    langCode
  } = props

  const[authObj, authMethods] = useContext(AuthContext)
  eqLogger('authObj === ', authObj)

  useEffect(() => {
    authMethods.signInRedirect({langCode})
  }, [ authMethods, langCode ])
  return (
    <>
      <SEO
        title={t`Redirecting you to the Dashboard`}
        description={t`Building the Sharing Economy to Enable The Global Computing Network`}
        lang={langCode}
      />
      <div className="callbackloader">
        <div className="callbackloader-spinner">
          <div className="callbackloader-spinner-bar">
          </div>
        </div>
        <div className="callbackloader-header">
          <Trans>
            Verifying Account ...
          </Trans>
        </div>
      </div>
    </>
  )
}

export default CallbackPageInner
