import React from "react"

import Layout from "../components/layout"


import { i18n } from '@lingui/core'
import { messages } from '../locales/ja/messages'
import CallbackPageInner from "../innerPages/CallbackPageInner"


const CallbackPage = () => {
  const langCode = "ja"
  i18n.load(langCode, messages)

  return (
    <Layout
      langCode={langCode}
      messages={messages}
    >
      <CallbackPageInner langCode={langCode}/>
    </Layout>
  )
}

export default CallbackPage
